import React from 'react'
import Map from '../components/map/map'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useBreadCrumbs } from '../hooks/useBreadcrumbs'
import {
  Grid,
  TextField,
  Box,
  Icon,
  Button,
  Typography,
  Select,
  FormControl,
} from '@material-ui/core'

const ContactsPage = () => {
  const breadCrumbs = useBreadCrumbs('contacts')

  return (
    <Layout breadCrumbs={breadCrumbs} title="Контакты">
      <SEO title="Контакты" />
      <Grid container>
        <Grid item md={6} xs={12}>
          <Box mb={6}>
            <Box mb={3}>
              <Typography variant="h4">Напишите нам</Typography>
            </Box>
            <Typography variant="subtitle1">
              С удовольствием ответим на все ваши вопросы по телефону <br />
              +7 (3822) 215-911, 216-911. <br />
              <br />
              <b>Или просто заполните форму ниже:</b>
            </Typography>
            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contacts"
            >
              <input type="hidden" name="bot-field" />
              <Box mb={3}>
                <Box>
                  <TextField
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    margin="normal"
                    variant="outlined"
                    required
                  />
                </Box>
                <Box>
                  <FormControl variant="outlined">
                    <Select
                      native
                      inputProps={{
                        name: 'pathner-type',
                        id: 'pathner-type',
                      }}
                    >
                      <option value="individual">Физическое лицо</option>
                      <option value="legal_entity">Юридическое лицо</option>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <TextField
                    id="message"
                    label="Сообщение"
                    multiline
                    rows="4"
                    name="message"
                    margin="normal"
                    variant="outlined"
                    required
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  endIcon={<Icon>send</Icon>}
                  type="submit"
                >
                  Отправить
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box mb={3}>
            <Typography variant="h4">Центральный офис</Typography>
          </Box>
          <Box mb={3}>
            <Typography>
              <b>Адрес</b>: ул. Войкова, 8/6, Томск​, Россия, индекс 634009{' '}
              <a href="mailto:penetron.tomsk@mail.ru">penetron.tomsk@mail.ru</a>{' '}
              <br />
              <b>Телефоны</b>: +7 (3822) 215-911, 216-911
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography>
              <b>Приглашаем к сотрудничеству</b>
              <br />
              Проектные и подрядные организации, строительные магазины и
              супермаркеты
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Box mb={3}>
          <Typography variant="h4" component="h2">
            Карта проезда
          </Typography>
        </Box>
        <Map />
      </Grid>
    </Layout>
  )
}
export default ContactsPage
